.dl-component-label {
    /*font-family: 'Poppins', sans-serif !important; */
    fontFamily: "Roboto" !important;
    font-size: 14px !important;
    color: #5e6278 !important;
    font-weight: 500 !important;
    margin-bottom: 10px !important;
}

.dl-screen-title {
    color: #707070;
    font-weight: 600;
    font-size: 18px;
}


.selected-chip, .unselected-chip:hover {
    color: #009ef7;
    background-color: #f1faff !important;
    border: 1px dashed #009ef7;
    font-weight: 500;
    font-size: 14px;
    min-width: 50px;
    max-width: 100%;
    display: block;
    line-height: 1.5;
}

.unselected-chip {
    color: #656565;
    border: 1px dashed #7e8299;
    font-size: 14px;
    min-width: 50px;
    font-weight: 400;
    max-width: 100%;
    display: block;
    line-height: 1.5;
}

@media (max-width: 767px) {
    .unselected-chip, .selected-chip
    {
        font-size: 12px;
    }

    .btn
    {
        padding:5px 2px !important;
    }

}

.snackbar-info .MuiSnackbarContent-root {
    background-color: #003f61 !important;
}

.snackbar-success .MuiSnackbarContent-root {
    background-color: #5db77a !important;
}

.snackbar-warning .MuiSnackbarContent-root {
    background-color: #f08372 !important;
}

.snackbar-danger .MuiSnackbarContent-root {
    background-color: #d32f2f !important;
}

