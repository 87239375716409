* {
    margin: 0;
}

input[type="file"] {
    display: none !important;
}

.innerWindowHeight {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    /*height:  var(--vh, 100vh) ;*/
}

.upload-file-P8 {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 115, 255);
    min-height: 30px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 5px;
    text-transform: none;
    /*min-width: 100px !important;*/
    font-family: Barlow;
    cursor: pointer;
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-top: 3px;
    border: 1px solid rgb(0, 115, 255);
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 50px 200px;
    cursor: pointer;
    /*margin : 3% 3%;*/
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    font-size: 20px;
    z-index: 17;
}

.gjs-sm-icon {
    color: black !important;
    font-weight: 400 !important;
    font-size: 13px;
}

body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 13px;
    font-weight: 400;
    font-family: Poppins, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

@-webkit-keyframes apploader {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes apploader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.dl-app-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    border: 0;
    width: 100%;
    z-index: 9999;
}

.dl-app-loader {
    border: 10px solid #073f61;
    border-radius: 50%;
    border-top: 10px solid #f3f3f3;
    width: 80px;
    height: 80px;
    opacity: 0.8;
    -webkit-animation: apploader 2s linear infinite; /* Safari */
    animation: apploader 2s linear infinite;
    z-index: 9999;
}

.row > [class^="col-"], .row > [class*=" col-"] {
    padding-left: 8px;
    padding-right: 8px;
}

.bg-success-view {
    color: #1fc169;
    background-color: #f1fff7;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px dashed #1fc169;
}

.row {
    margin-left: -8px !important;
    margin-right: -8px !important;
}

.container-fluid {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.link-primary {
    color: #0073ff;
    font-size: 16px;
}

.res-app-bar-header > header {
    background-color: white;
}

.draggable-content > div {
    display: contents;
}

.dl-snackbar > .MuiSnackbarContent-message {
    width: 100%;
}

.gjs-sm-header {
    display: none !important;
}


.snackbar-info .MuiSnackbarContent-root {
    background-color: #0073ff !important;
}

.snackbar-text .MuiSnackbarContent-message, .snackbar-text .MuiAlert-message {
    font-size: 14px !important;
}

.text-red {
    color: #ff0000;
}

.left-panel {
    background-color: #dbe6f8;
    height: 600px;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.legend {
    float: unset;
}

.flex-root {
    flex: 1;
}

.email-template-editor > div {
    height: 95vh;
}

.border-rounded {
    border-radius: 6px;
}

::-webkit-scrollbar {
    width: 7px;
    height: 4px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #656565;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.sub-title {
    font-size: 16px;
}

.para-text {
    font-size: 15px;
}

.d-grid {
    display: grid;
}

.text-primary {
    color: #0073ff !important;
}

.bg-light {
    background-color: #f5f5f5 !important;
}

.text-dark {
    color: #181c32 !important;
}

.active-link .menu-item {
    background-color: #0073ff;
    color: white !important;
    position: relative;
}

.active-link span {
    color: white !important;
}

.active-bottom-option-link .menu-item {
    background-color: #0073ff;
    color: white !important;
    position: relative;
}

.active-bottom-option-link {
    background-color: #fff;
}

.bottom-menu-rippleclass {
    position: relative;
    overflow: hidden;
}

.bottom-menu-rippleclass::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0;
    pointer-events: none;
    transition: all 0.4s ease-out;
}

.bottom-menu-rippleclass:active::after {
    width: 200%;
    height: 200%;
    opacity: 1;
}


.active-bottom-option-link .activatedMenu {
    background-color: #fff;
}

.active-bottom-option-link .horizontal-line {
    height: 3px;
    background-color: var(--primary) !important;
}

.active-bottom-option-link span {
    color: var(--primary) !important;
    font-weight: 500;
}


.active-side-option-link .menu-item {
    background-color: #0073ff;
    color: white !important;
    position: relative;
}

.active-side-option-link {
    background-color: #0B407D;
}

.active-side-option-link .activatedMenu {
    background-color: #fff;
}

.active-side-option-link .horizontal-line {
    height: 5px;
    background-color: var(--secondary-main) !important;
}

.active-side-option-link .sideMenuLabel {
    color: var(--white) !important;
}

.bottom-menu:hover {
    background-color: #E7ECF2 !important;
}

.right-section-container {
    margin-left: 100px;
}

.MuiDrawer-paper {
    border-right: 0 !important;
}

.left-panel-submenus .MuiAccordion-root {
    background-color: #212529;
}

.MuiAccordionDetails-root {
    padding: 0 !important;
}

.left-panel-submenus .MuiSvgIcon-root {
    width: 25px;
    fill: #888c9f;
}

.left-panel-submenus .MuiButtonBase-root.MuiIconButton-root.MuiAccordionSummary-expandIcon.MuiIconButton-edgeEnd {
    position: absolute;
    right: 12px;
}

.bullet-dot {
    width: 4px;
    height: 4px;
    display: inline-block;
    background-color: #b5b5c3;
    border-radius: 100% !important;
    margin-bottom: 3px;
}

.bullet-dot:hover, .active-link .bullet-dot {
    background-color: #ffffff;
}

.subMenu .MuiAccordionSummary-content {
    display: unset;
}

.active-link .menu-item:after {
    width: 0;
    height: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
    border-right-color: #f5f8fa;
    border-top-width: 22px;
    border-bottom-width: 22px;
    content: " ";
    position: absolute;
}

.menu-item {
    padding: 15px 0;
    color: #888c9f;
}

.menu-item:hover {
    color: #ffffff;
}

.drawer-shift {
    margin-left: 250px;
}

.fw-bold {
    font-weight: 500 !important;
}

.text-muted {
    color: #a1a5b7 !important;
}

.border-bottom-dashed {
    border-bottom: 2px dashed #e4e6ef;
}

.right-section-container .MuiSnackbar-anchorOriginTopCenter {
    top: 90px;
    left: 50%;
    right: auto;
    margin-left: 40px;
    transform: translateX(-50%);
}

.drawer-shift .MuiSnackbar-anchorOriginTopCenter {
    top: 90px;
    left: 50%;
    right: auto;
    margin-left: 125px;
    transform: translateX(-50%);
}

.icon-size {
    font-size: 20px;
}

.avatar-img {
    width: 28px !important;
    height: 28px !important;
    object-fit: cover;
    font-weight: 600 !important;
    font-size: 13px !important;
}

.avatar-img-lg {
    width: 50px !important;
    height: 50px !important;
    object-fit: cover;
    font-weight: 600 !important;
    font-size: 20px !important;
}

.cursor {
    cursor: pointer;
}


.bg-warning-view {
    color: #E18600;
    background-color: #FFFAF2;
    font-size: 16px;
    padding: 5px 12px;
    border: 1px solid #E18600;
}

.bg-success-view {
    color: #1fc169;
    background-color: #f1fff7;
    font-size: 16px;
    padding: 5px 12px;
    border-radius: 5px;
    border: 1px solid #1fc169;
}

.bg-danger-view {
    color: #f1416c;
    background-color: #ffeded;
    font-size: 16px;
    padding: 5px 12px;
    border-radius: 5px;
    border: 1px solid red;
}

.bg-primary-view {
    color: #0073ff;
    background-color: #f1faff;
    font-size: 18px;
    padding: 5px 12px;
    border-radius: 5px;
    border: 1px solid #0073ff;
}

.bg-error-view {
    color: #f1416c;
    background-color: #fff2f2;
    font-size: 16px;
    padding: 5px 12px;
    border-radius: 5px;
    border: 1px solid #f1416c;
}

.table-head {
    color: #666666 !important;
    font-weight: 400 !important;
    font-size: 16px !important;
}

.badge-light {
    color: #474545;
    background-color: #e3e3e3;
    font-size: 13px;
    font-weight: 400;
}

.table-row-padding:not(:last-of-type) {
    padding: 0 0 30px 0;
}

.MuiTableCell-root {
    font-size: 12px !important;
}

.cursor {
    cursor: pointer;
}

a {
    text-decoration: none;
    color: inherit;
}

.top-margin-more {
    margin-top: 14%;
}

@media (max-width: 992px) {
    .drawer-shift .MuiSnackbar-anchorOriginTopCenter, .right-section-container .MuiSnackbar-anchorOriginTopCenter {
        top: 12%;
        left: 50%;
        width: fit-content;
        right: auto;
        transform: translateX(-50%);
        margin: 0 10px 0 0 !important;
    }

    .right-section-container, .drawer-shift {
        margin-left: 0;
    }

    .avatar-img img {
        width: 30px;
        height: 30px;
        border-radius: 30px;
    }
}

@media (max-width: 767px) {
    .container-fluid {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }
}

@media (max-width: 600px) {
    .popover-box {
        min-width: 300px;
    }
}


.MuiToolbar-regular {
    min-height: 44px !important;
}


.dl-snackbar > .MuiSnackbarContent-message {
    width: 100%;
}

.snackbar-text .MuiSnackbarContent-message, .snackbar-text .MuiAlert-message {
    font-size: 14px !important;
}

@media (max-width: 992px) {
    .gjs-fonts:before {
        font-size: 3.3em !important;
    }

    .drawer-shift .MuiSnackbar-anchorOriginTopCenter, .right-section-container .MuiSnackbar-anchorOriginTopCenter {
        top: 45px;
        left: 50%;
        width: fit-content;
        right: auto;
        transform: translateX(-50%);
        margin: 0 10px 0 0 !important;
    }
}

.active-link .menu-item {
    background-color: #0073ff;
    color: white !important;
    position: relative;
}

.MuiAccordion-root.Mui-expanded {
    margin: 0 !important;
}

.drawer .MuiAccordion-root {
    background-color: transparent !important;
}

.MuiAccordion-rounded:first-child {
    width: 100%;
}

.row-striped:not(:nth-of-type(even)) {
    background-color: #f4f9fc;
}

.list-item-hover:hover {
    background-color: #f4f9fc;
}

.card-item {
    padding: 2px 8px;
    border-radius: 10px;
    border: 2px solid var(--gray);
}

.card-item-selected {
    padding: 2px 8px;
    border-radius: 10px;
    border: 2px solid var(--primary);
    position: relative;
}

.card-selected-icon {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 2px solid #575757
}

.card-selected-icon-right {
    position: absolute;
    right: 3px;
    top: -1px;
    font-size: 16px;
}

/* bootstrap margin padding classes starts */
.pt-1 {
    padding-top: .25rem !important;
}

.pr-1 {
    padding-right: .25rem !important;
}

.pb-1 {
    padding-bottom: .25rem !important;
}

.pl-1 {
    padding-left: .25rem !important;
}

.px-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
}

.py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
}

.pt-2 {
    padding-top: .5rem !important;
}

.pr-2 {
    padding-right: .5rem !important;
}

.pb-2 {
    padding-bottom: .5rem !important;
}

.pl-2 {
    padding-left: .5rem !important;
}

.px-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important;
}

.py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
}

.pt-3 {
    padding-top: 1rem !important;
}

.pr-3 {
    padding-right: 1rem !important;
}

.pb-3 {
    padding-bottom: 1rem !important;
}

.pl-3 {
    padding-left: 1rem !important;
}

.py-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.pt-4 {
    padding-top: 1.5rem !important;
}

.pr-4 {
    padding-right: 1.5rem !important;
}

.pb-4 {
    padding-bottom: 1.5rem !important;
}

.pl-4 {
    padding-left: 1.5rem !important;
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.pt-5 {
    padding-top: 3rem !important;
}

.pr-5 {
    padding-right: 3rem !important;
}

.pb-5 {
    padding-bottom: 3rem !important;
}

.pl-5 {
    padding-left: 3rem !important;
}

.px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

/* bootstrap margin padding classes ends */

.left-panel-submenus .MuiSvgIcon-root {
    width: 25px;
    fill: #888c9f;
}

.menu-item .MuiButtonBase-root.MuiIconButton-root.MuiAccordionSummary-expandIcon.MuiIconButton-edgeEnd {
    position: absolute;
    right: 12px;
}

.badge-dot-warning-light{
    background-color: var(--secondary-main);
    border-radius: 50%;
    height: 9px;
    width: 9px;
}
.badge-dot-danger-light{
    background-color: var(--danger);
    border-radius: 50%;
    height: 9px;
    width: 9px;
}
.badge-dot-success-light{
    background-color: var(--success);
    border-radius: 50%;
    height: 9px;
    width: 9px;
}
.badge-dot-primary{
    background-color: var(--primary);
    border-radius: 50%;
    height: 9px;
    width: 9px;
}
.badge-dot-light{
    background-color: var(--light-gray);
    border-radius: 50%;
    height: 9px;
    width: 9px;
}

.badge-warning-light {
    color: #ff9100;
    background-color: #f2e9c2;
    font-size: 13px;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: 500;
}

.badge-danger-light {
    color: #f1416c;
    background-color: #ffeded;
    font-size: 15px;
    font-weight: 500;
    padding-left: 15px;
    padding-right: 15px;
}

.template-option-select {
    background-color: #f4f9fc;
}


.template .MuiAccordionSummary-root {
    background-color: #fff !important;
}

.showIframe iframe {
    display: block !important;
}

.lds-ring {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    border: 0;
    width: 100%;
    z-index: 9999;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

* ::-webkit-scrollbar {
    width: 7px;
    height: 4px;
}


* ::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
}

* ::-webkit-scrollbar-thumb {
    background: #656565 !important;
    border-radius: 10px !important;
}


* ::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
}

.fa-cog {
    order: 3
}

.fa-paint-brush {
    order: 2
}

.gjs-clm-tag {
    color: white !important;
}

@media (max-width: 992px) {
    .cell {
        width: 100% !important;
        display: block !important;
    }
}

.drawer-width .MuiDrawer-paper {
    width: 25%;
    height: calc(100% - 80px);
}

.drawer-width .MuiDrawer-paper {
    width: 25%;
    height: 100%;
    top: 41px;
}

.btn-group-templates .MuiButton-root {
    color: #f6f6f6;
    background: rgba(0, 0, 0, 0.4);
    padding: 10px 10px;
}

.btn-group-templates .MuiButton-root:hover {
    color: rgba(0, 0, 0, 0.4);
    background: #ffffff;
}

.btn-group-templates .MuiButton-outlined {
    border-color: #f6f6f6;
}

.badge-light-success {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    color: #029c48;
    font-size: 15px;
    font-weight: 500;
    background-color: #d5f9e7;
    border-radius: 4px;
}

.badge-success {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    background-color: #198754;
    border-radius: 4px;
}

.badge-light-warning {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    color: #ff9100;
    font-size: 15px;
    font-weight: 500;
    background-color: #f2e9c2;
    border-radius: 4px;
}

.light-warning {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    color: #E18600;
    font-size: 15px;
    font-weight: 400;
    border-radius: 4px;
}

.badge-light-grey {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 400;
    background-color: #b3b5b1;
    border-radius: 4px;
}

.badge-light-danger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    color: #f1416c;
    background-color: #ffeded;
    font-size: 15px;
    font-weight: 400;
    border-radius: 4px;
}

.badge-light-info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    color: #fff;
    background-color: #0073ff;
    font-size: 15px;
    font-weight: 400;
    border-radius: 4px;
}

.badge-light-primary {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    background-color: #003471;
    border-radius: 4px;
}

.badge-xs {
    min-height: 16px;
    font-size: 12px;
    padding-left: 8px;
    padding-right: 8px;
}

.badge-sm {
    min-height: 18px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
}

.badge-md {
    min-height: 22px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 16px;
}

.border-rounded {
    border-radius: 6px;
}

.contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-bottom: 64px;
}

.steps {
    bottom: -15px;
    z-index: 1;
}

.stepsCircle {
    background-color: #D5EEF1;
    height: 20px;
    width: 32px;
    display: flex;
    border: 4px solid #2376D4;
    border-radius: 50%;
}

.stepsLine {
    height: 3px;
    background: #75AEEF;
    width: 100%;
}

.text-block {
    border: 3px solid var(--secondary-main);
    color: #212529;
    margin: 0 20px 20px 20px;
    padding: 0 15px;
    position: relative;
    border-radius: 8px;
    background: linear-gradient(to top, #fff8ed, 20%, white);
}

.contents::after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: 40px;
    z-index: 0;
    left: 0;
    border-bottom: 3px solid #75AEEF;
    margin-left: 50%;
    margin-bottom: 5px;
}

.text-block.arrow-top:after {
    content: " ";
    position: absolute;
    bottom: -16px;
    border-top: 15px solid var(--secondary-main);
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: none;

}

.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: fixed;
    top: 44px;
    right: 0;
    border: 0;
    width: 100%;
    z-index: 9999;
    background: lightgray;
}

.loader {
    border: 10px solid #073f61;
    border-radius: 50%;
    border-top: 10px solid #f3f3f3;
    width: 80px;
    height: 80px;
    opacity: 0.8;
    -webkit-animation: apploader 2s linear infinite; /* Safari */
    animation: apploader 2s linear infinite;
    z-index: 9999;
}

.spanner, .overlay {
    opacity: 0.8
}

.dl-component-label {
    font-family: 'Roboto', sans-serif !important;
    font-size: 14px !important;
    color: #5e6278 !important;
    font-weight: 500 !important;
    margin-bottom: 4px !important;
}

.draggable-content > div {
    display: contents;
}

.arrowVisible {
    display: none;
}

.titleHover:hover .arrowVisible {
    display: block;
}

.fixedHeader {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    padding: 0;
}

.gitPractice {
    color: red;
}

.tooltipIconColor {
    color: #5A5A5A;
}

.btnHover:hover {
    color: white;
    background-color: #0a53be;
}

/*userEmail Verification*/
.otpInputBoxStyle {
    min-width: 40px;
    flex-grow: 1;
    height: 80px;
    padding: 10px;
    background-color: #F4F4F4;
    border-radius: 5px;
    border: 1px solid #B3B3B3
}

.otpInputBoxStyleMobile {
    width: 35px;
    height: 35px;
    padding: 10px;
    background-color: #F4F4F4;
    border-radius: 5px;
    border: 1px solid #B3B3B3
}

/*Todo - take in common component*/
.dropdown .css-1jzt7ea .MuiTextField-root {
    margin: 0 0 3px 0 !important;
}

/*userActivity verticalstick implementation*/
.vertical-stick {
    background-color: var(--primary);
    padding: 1px;
    max-width: 0.3px;
    margin-top: 0;
    border-radius: 5px;
    flex-grow: 1;
    margin-left: 9px;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: 36px !important;
    height: 36px !important;
}

.doubleDataDropdown > .btn:focus {
    box-shadow: unset !important;
    outline: 0;
    border: 2px solid #0073ff !important;
}

.fixedSubHeader {
    position: -webkit-sticky;
    position: sticky;
    top: 94px;
    z-index: 1100
}

/*vertical line used in dashboard*/
.verticaline {
    border-left: 5px solid blue;
    height: 50px;
    margin-left: 5px;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
    max-height: 300px !important;
}

.glacierDrawerHeight {
    height: calc(100vh - 44px);
}

.labelHoverData:hover {
    background-color: rgb(0, 115, 255) !important;
}

.labelHoverDataDisable:hover {
    background-color: #e5e5e5 !important;
}

.disableCursor {
    cursor: unset;
}

.resAppBarOptionHover:Hover {
    background-color: rgb(65 65 65) !important;
}

.image-upload > input {
    display: none;
}

/*To add scroll in contents block removing resApp bar and footer*/
.app-bar {
    display: flex;
}

.content {
    display: flex;
    overflow-x: hidden;
}

.dropdown-toggle::after {
    color: #276DBC
}

@media (max-width: 650px) {
    .gjs-fonts:before {
        font-size: 2em !important;
    }
}


/*new batdges*/
.iphoneBackgroundColor {
    background-color: rgb(232, 233, 234) !important;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}


a:hover {
    color: inherit !important;
}

/* Segment CSS */
.segment-table-head {
    padding: 6px 6px 0 0  !important;
    font-family: 'Barlow', sans-serif !important;
    flex: 4 1;
    font-size: 14px !important;
    color: #575757 !important;
    font-weight: 500 !important;
}

.segment-chip-selected {
    background-color: #cce3ff !important;
}

.segment-chip-normal {
    background-color: #8B8B8B !important;
}

.segment-buttonGroup-button {
    border-color: #B2B2B2;
    min-width: 48px;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 16px;
    font-family: Barlow sans-serif;
}


.segment-tab-text {
    text-align: center;
    font-size: 18px;
    font-family: Barlow, normal, sans-serif;
    font-weight: 600;
    letter-spacing: 0;
    color: var(--light-gray);
    opacity: 1;
}

/*.segment-summary-block {*/
/*    min-width: 284px;*/
/*    position: relative;*/
/*    height: 84px;*/
/*    background: #fff;*/
/*    border: 1px solid #2376D4;*/
/*    opacity: 1;*/
/*    border-radius: 5px;*/
/*    padding: 0.25rem;*/
/*    margin-top: 1rem;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/

.segment-summary-block-all {
    min-width: 200px;
    position: relative;
    height: 60px;
    background: #E8F3FF;
    /*background: #D2E7FF;*/
    opacity: 1;
    border-radius: 5px;
    padding: 0.25rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*color: #333333 !important;*/
    -moz-box-shadow: 0 0 3px #ccc;
    -webkit-box-shadow: 0 0 3px #ccc;
    box-shadow: 0 0 5px #ccc;
}

.segment-summary-block-common {
    min-width: 200px;
    position: relative;
    height: 60px;
    background: #DBECFF;
    opacity: 1;
    border-radius: 5px;
    padding: 0.25rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -moz-box-shadow: 0 0 3px #ccc;
    -webkit-box-shadow: 0 0 3px #ccc;
    box-shadow: 0 0 5px #ccc;
}


.segment-info-block {
    min-height: 55px;
    border-radius: 8px;
    opacity: 1
}

.segment-info-block-text {
    font-size: 16px;
    color: #575757;
    font-family: Barlow, Medium, sans-serif;
    text-align: left;
}

.segment-summary-block-count {
    letter-spacing: 0;
    text-align: center;
    font-family: Barlow normal, sans-serif;
    font-color: #2376D4 !important;
    font-weight: 600;
    font-size: 32px;
}

.segment-summary-block-text {
    letter-spacing: 0;
    text-align: center;
    font-family: Barlow normal, sans-serif;
    color: #2376D4 !important;
    font-weight: 600;
    font-size: 16px;
}

.segment-tab {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: max-content;
    height: 40px;
    border-radius: 2px;
    opacity: 1
}

.segment-main-tab-text {
    text-align: center;
    font-size: 18px;
    font-family: Barlow, normal, sans-serif;
    font-weight: 600;
    letter-spacing: 0;
    color: #cdcdcd;
    opacity: 1;
}

.segment-summary-block {
    min-width: 200px;
    position: relative;
    height: 60px;
    -moz-box-shadow: 0 0 3px #ccc;
    -webkit-box-shadow: 0 0 3px #ccc;
    box-shadow: 0 0 5px #ccc;
    /*border: 1px solid #2376D4;*/
    opacity: 1;
    border-radius: 5px;
    padding: 0.25rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.segment-info-block {
    min-height: 55px;
    border-radius: 8px;
    opacity: 1
}

.segment-info-block-text {
    font-size: 16px;
    color: #575757;
    font-family: Barlow, Medium, sans-serif;
    text-align: left;
}

.sampleRecordsWidth {
    max-width: calc(100% - 316px);
    min-width: calc(100% - 316px)
}

.sampleRecordsWidthForExpressSetup {
    max-width: calc(100% - 230px);
    min-width: calc(100% - 230px)
}

.segment-tooltip {
    color: white;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.segment-tooltip-block {
    display: flex;
    justify-content: center;
    align-items: center;
    background: lightGray;
    border: 1px solid #A9A9A9;
    height: 30px;
    border-radius: 5px;
    position: relative;
    padding: 0.25rem;
    margin-top: 1rem
}

.segment-chip-dropdown {
    /*padding: var(--bs-dropdown-item-padding-y);*/
    fontWeight: 400;
    color: var(--bs-dropdown-link-color);
    textAlign: inherit;
    textDecoration: none;
    whiteSpace: nowrap;
    border: 0
}

.segment-chip-dropdown :hover {
    background-color: lightgray;
}
.segment-table thead tr th:first-child {
    border-top-left-radius: 6px;
}
/*.segment-table thead tr th:last-child {*/
/*    border-top-right-radius: 7px;*/
/*}*/

.segmentContentCatalogScroll::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.segmentContentCatalogScroll::-webkit-scrollbar-thumb {
    background: var(--grey) !important;
    border-radius: 10px;
}

.segmentContentCatalogScroll::-webkit-scrollbar-thumb:hover {
    background: var(--grey) !important;
    border-radius: 10px;
}

@media (max-width: 992px) {
    .cell {
        width: 100% !important;
        display: block !important;
    }

    .sampleRecordsWidth {
        max-width: 100%;
        min-width: 100%
    }
}

.statistic-block-border {
    border: 1px solid #2376D4;
    border-radius: 7px;
}

.beta-chip {
    width: 68px;
    padding-left: -1px;
    margin-left: -20px;
    margin-right: -14px;
    height: 19px;
    font-weight: 500;
    margin-top: -1px;
    background-color: var(--secondary-main);
    transform: rotate(-40deg);
}

.campaigns-stats-count-label {
    font-weight: 600;
    font-size: 16px;
}

.campaigns-stats-count-value {
    font-size: 12px;
}

.campaigns-stats-count-block {
    flex: 1;
    min-width: 80px;
    max-width: 120px;
}

.logo-alignment {
    margin-left: -5px;
}

.role-badge{
    border-radius: 4px;
    background-color: var(--gray);
    color: var(--white);
    font-size: 10px;
}

@media (max-width: 992px) {
    .beta-chip {
        font-size: 10px;
        height: 10px;
        text-align: left;
        margin-top: 1px;
    }

    .logo-alignment {
        margin-left: -20px;
    }
}


.header-icon{
    color:var(--primary-xdark);
}
.default-icon{
    color:var(--light-gray);
}
.disable-icon{
    color:var(--grey);
}
.active-icon{
    color:var(--primary);
}
.success-icon{
    color:var(--success);
}
.warning-icon{
    color:var(--secondary-main);
}
.error-icon{
    color:var(--danger);
}
.icon-size{
    font-size: 16px;
}
.dropdown-item.active, .dropdown-item:active{
    background-color: white;
}

.segment-block {
    -moz-box-shadow: 0 0 3px #ccc;
    -webkit-box-shadow: 0 0 3px #ccc;
    box-shadow: 0px 4px 10px 0px #ccc;
    opacity: 1;
    border-radius: 5px;
    color: #FFF;
}
.customer-content{
    background-color: #E4F5FF;
}
.prospects-content{
    background-color: #E0FAFF;
}
.lead-content{
    background-color: #E2F1F4;
}
.none-content{
    background-color: #e1e1e1;
}
.theme-font-content{/*h4*/
    font-size: var(--font-size-md);
}
.theme-font-normal{
    font-weight: var(--font-weight-normal);
}



.borderEndTopSuccess {
    transform: skewX(45deg);
    border-right: 2px solid var(--success);

}
.borderEndTopDanger {
    transform: skewX(45deg);
    border-right: 2px solid var(--danger);

}
.borderEndTopPrimary {
    transform: skewX(45deg);
    border-right: 2px solid var(--primary);

}
.borderEndTopGray {
    transform: skewX(45deg);
    border-right: 2px solid var(--grey);

}


.borderEndBottomSuccess {
    transform: skewX(-45deg);
    border-right: 2px solid var(--success);

}
.borderEndBottomDanger {
    transform: skewX(-45deg);
    border-right: 2px solid var(--danger);

}
.borderEndBottomPrimary {
    transform: skewX(-45deg);
    border-right: 2px solid var(--primary);

}
.borderEndBottomGray {
    transform: skewX(-45deg);
    border-right: 2px solid var(--grey);

}








/* Global CSS, you probably don't need that */

.clearfix:after {
    clear: both;
    content: "";
    display: block;
    height: 0;
}

.container {
    font-family: 'Lato', sans-serif;
    width: 1000px;
    margin: 0 auto;
}

.nav {
    margin-top: 40px;
}

.pull-right {
    float: right;
}


/* Breadcrups CSS */

.arrow-steps .step {
    font-size: 14px;
    text-align: center;
    color: #666;
    cursor: default;
    margin: 0px 6px 6px 0px;
    padding: 7px 10px 7px 30px;
    min-width: 130px;
    float: left;
    position: relative;
    background-color: #EEEEEE;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: background-color 0.2s ease;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
    content: " ";
    position: absolute;
    top: 0;
    right: -16px;
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-bottom: 17px solid transparent;
    border-left: 17px solid #EEEEEE;
    z-index: 2;
    transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
    right: auto;
    left: 0;
    border-left: 17px solid #fff;
    z-index: 0;
}

.arrow-steps .step:first-child:before {
    border: none;
}

.arrow-steps .step:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.arrow-steps .step span {
    position: relative;
}

.arrow-steps .step span:before {
    opacity: 0;
    content: "✔";
    position: absolute;
    top: -2px;
    left: -20px;
}

.arrow-steps .step.done span:before {
    opacity: 1;
    -webkit-transition: opacity 0.3s ease 0.5s;
    -moz-transition: opacity 0.3s ease 0.5s;
    -ms-transition: opacity 0.3s ease 0.5s;
    transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.current {
    color: #fff;
    background-color: #EEEEEE;
}

.arrow-steps .step.current:after {
    border-left: 17px solid #EEEEEE;
}


.arrow-steps .step-error:after {
    border-left: 17px solid #FFEAE2 !important;
}
.arrow-steps .step-success:after {
    border-left: 17px solid #E1FFE5 !important;
}
.arrow-steps .step-warning:after {
    border-left: 17px solid #FFF2D7 !important;
}
.arrow-steps .step-progress:after {
    border-left: 17px solid #1E52BD !important;
}
.arrow-steps .step-none:after {
    border-left: 17px solid #FCFDFF !important;
}

@media (max-width: 992px) {

    .arrow-steps .step {
        font-size: 14px;
        text-align: center;
        color: #666;
        cursor: default;
        margin: 0px 6px 6px 0px;
        padding: 7px 10px 7px 30px;
        min-width: 110px;
        float: left;
        position: relative;
        background-color: #EEEEEE;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        transition: background-color 0.2s ease;
    }
}

.content-box{
    width:250px;
    min-width:250px;
    max-width:250px;
}

@media (max-width: 576px) {
    .content-box{
        width:100%;
        min-width:100%;
        max-width:100%;
    }
}


.template-data{
    color: black;
}