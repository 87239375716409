:root {

    /* Colors: */
    --primary: #1E53BD;
    --primary-xlight: #DEEDFF;
    --primary-light: #60A3EF;
    --primary-main: #7AA6D7;
    --white: #FFFFFF;
    --primary-dark: #609adc;
    --primary-xdark: #276dbc;
    --secondary-main: #FEA133;
    --black: #333333;
    --gray: #999999;
    --grey: #c5c5c5;
    --light-gray: #666666;
    --success: #029C48;
    /*--warning: '#E18600';*/
    --danger: #D72F2F;
    --link: #0073ff;

    /* Font/text values */
    --font-family-barlow: Barlow;
    --font-style-normal: normal;
    --font-weight-bolder: 700;
    --font-weight-bold: 600;
    --font-weight-semibold: 500;
    --font-weight-normal: normal;
    --font-size-14: 14px;
    --font-size-15: 15px;
    --character-spacing-0: 0px;
    --line-spacing-18: 18px;
    --line-spacing-20: 20px;

    /*Font Size Value*/
    --font-size-xxs: 10px;
    --font-size-xs: 12px;
    --font-size-sm: 14px;
    --font-size-md: 16px;
    --font-size-lg: 20px;
    --font-size-xl: 24px;
    --font-size-xxl: 28px;
    --font-size-xxxl: 32px;

}

