.character-style-1 {
    font-family: var(--font-family-barlow);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-14);
    line-height: var(--line-spacing-20);
    letter-spacing: var(--character-spacing-0);
    color: var(--black);
}

.character-style-2 {
    font-family: var(--font-family-barlow);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-15);
    line-height: var(--line-spacing-18);
    letter-spacing: var(--character-spacing-0);
    color: var(--white);
}

.character-style-3 {
    font-family: var(--font-family-barlow);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-15);
    line-height: var(--line-spacing-18);
    letter-spacing: var(--character-spacing-0);
    color: var(--primary-dark);
}

.bg-primary-dark {
    background-color: var(--primary-dark);
}

.bg-primary-xdark {
    background-color: var(--primary-xdark);
}

.bg-primary-main {
    background-color: var(--primary-main);
}

.bg-primary-light {
    background-color: var(--primary-light);
}

.bg-primary-xlight {
    background-color: var(--primary-xlight);
}

.bg-gray {
    background-color: #959494;
}

.bg-color-customer {
    background-color: #E4F5FF;
}

.bg-color-prospect {
    background-color: #E0FAFF;
}

.bg-color-lead {
    background-color: #E2F1F4;
}

.text-success {
    color: #029C48;
}

.text-warning {
    color: #E18600;
}

.text-danger {
    color: #D72F2F;
}

.badge-customer {
    background-color: #2376D4;
    padding: 3px 15px 5px 15px;
    color: var(--white);
    border-radius: 8px;
    font: normal var(--font-weight-semibold) 16px Barlow;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
}

.badge-prospect {
    background-color: #4997ED;
    padding: 3px 15px 5px 15px;
    color: var(--white);
    border-radius: 8px;
    font: normal var(--font-weight-semibold) 16px Barlow;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
}

.badge-lead {
    background-color: #7CB1EB;
    padding: 3px 15px 5px 15px;
    color: var(--white);
    border-radius: 8px;
    font: normal var(--font-weight-semibold) 16px Barlow;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
}

.invited-badge {
    background-color: #9AAE4D;
    padding: 3px 15px 5px 15px;
    color: var(--white);
    border-radius: 8px;
    font: normal var(--font-weight-semibold) 16px Barlow;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
}

.success-badge {
    background-color: #6FAB3C;
    padding: 3px 15px 5px 15px;
    color: var(--white);
    border-radius: 8px;
    font: normal var(--font-weight-semibold) 16px Barlow;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
}
.gray-md-badge {
    background-color: #B8B8B8;
    padding: 3px 15px 5px 15px;
    color: var(--white);
    border-radius: 8px;
    font: normal var(--font-weight-semibold) 16px Barlow;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
}

.gray-sm-badge {
    background-color: #B8B8B8;
    padding: 3px 6px 5px 6px;
    color: var(--white);
    border-radius: 8px;
    font: normal var(--font-weight-semibold) 16px Barlow;
    display: flex;
    justify-content: center;
    align-items: center;
    display: inline-grid;
}

#text-display-textcampaignDetail-smallView {
    color: #B2B2B2 !important;
    font-size: 24px !important;
    font-weight: 500;
}

.all-badge {
    background-color: #4380C5;
    padding: 3px 6px 5px 6px;
    color: var(--white);
    border-radius: 8px;
    font: normal var(--font-weight-semibold) 14px Barlow;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
}

.all-lg-badge {
    background-color: #4380C5;
    padding: 5px 14px 7px 14px;
    color: var(--white);
    border-radius: 8px;
    font: normal var(--font-weight-semibold) 14px Barlow;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    font-size: 18px;
}

.all-lg-badge-outlined {
    background-color: #fff;
    padding: 5px 14px 7px 14px;
    color: #4380C5;
    border-radius: 8px;
    font: normal var(--font-weight-semibold) 14px Barlow;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    font-size: 18px;
    border: 1px solid #4380C5;
}

.draft-badge {
    background-color: var(--secondary-main);
    padding: 3px 15px 5px 15px;
    color: var(--white);
    border-radius: 8px;
    font: normal var(--font-weight-semibold) 14px Barlow;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
}
.unverified-badge {
    background-color: var(--secondary-main);
    padding: 2px 10px 3px 10px;
    color: var(--white);
    border-radius: 4px;
    font: normal var(--font-weight-semibold) 12px Barlow;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
}
.unsubscibed-badge {
    background-color: #FEF3EF;
    padding: 1px 10px 3px 10px;
    color: var(--white);
    border-radius: 4px;
    font: normal var(--font-weight-semibold) 12px Barlow;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
}

.draft-lg-badge {
    background-color: #008AC7;
    padding: 5px 14px 7px 14px;
    color: var(--white);
    border-radius: 8px;
    font: normal var(--font-weight-semibold) 14px Barlow;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    font-size: 18px;
}

.draft-lg-badge-outlined {
    background-color: var(--white);
    padding: 5px 14px 7px 14px;
    color: #008AC7;
    border-radius: 8px;
    font: normal var(--font-weight-semibold) 14px Barlow;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    font-size: 18px;
    border: 1px solid #008AC7;
}

.activated-badge {
    background-color: #609adc;
    padding: 3px 15px 5px 15px;
    color: var(--white);
    border-radius: 8px;
    font: normal 500 14px Barlow;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
}

.activated-lg-badge {
    background-color: #0BB0CE;
    padding: 5px 14px 7px 14px;
    color: var(--white);
    border-radius: 8px;
    font: normal var(--font-weight-semibold) 14px Barlow;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    font-size: 18px;
    border: 1px solid #4380C5;
}

.activated-lg-badge-outlined {
    background-color: var(--white);
    padding: 5px 14px 7px 14px;
    color: #0BB0CE;
    border-radius: 8px;
    font: normal var(--font-weight-semibold) 14px Barlow;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    font-size: 18px;
    border: 1px solid #0BB0CE;
}

.execution-badge {
    background-color: #18ae97;
    padding: 3px 15px 5px 15px;
    color: var(--white);
    border-radius: 8px;
    font: normal var(--font-weight-semibold) 14px Barlow;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
}

.execution-lg-badge {
    background-color: #18AE97;
    padding: 5px 14px 7px 14px;
    color: var(--white);
    border-radius: 8px;
    font: normal var(--font-weight-semibold) 14px Barlow;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    font-size: 18px;
}

.execution-lg-badge-outlined {
    background-color: var(--white);
    padding: 5px 14px 7px 14px;
    color: #18AE97;
    border-radius: 8px;
    font: normal var(--font-weight-semibold) 14px Barlow;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    font-size: 18px;
    border: 1px solid #18AE97;
}

.completed-badge {
    background-color: #5db414;
    padding: 3px 15px 5px 15px;
    color: var(--white);
    border-radius: 8px;
    font: normal var(--font-weight-semibold) 14px Barlow;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
}

.completed-lg-badge {
    background-color: #5DB414;
    padding: 5px 14px 7px 14px;
    color: var(--white);
    border-radius: 8px;
    font: normal var(--font-weight-semibold) 14px Barlow;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    font-size: 18px;
}

.completed-lg-badge-outlined {
    background-color: var(--white);
    padding: 5px 14px 7px 14px;
    color: #5DB414;
    border-radius: 8px;
    font: normal var(--font-weight-semibold) 14px Barlow;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    font-size: 18px;
    border: 1px solid #5DB414;
}

.stopped-badge {
    background-color: #EE4E4E;
    padding: 3px 15px 5px 15px;
    color: var(--white);
    border-radius: 8px;
    font: normal var(--font-weight-semibold) 14px Barlow;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
}

@media (max-width: 992px) {
    .pageContainer {
        padding: 0 15px 0 15px !important;
        background-color: var(--white);
    }

    .pageContainerMargin {
        margin: 0 10px 0 10px !important;
    }

    .screenHeader {
        font-weight: var(--font-weight-semibold);
        font-style: var(--font-style-normal);
        font-size: 24px;
        color: var(--secondary-main);
        opacity: 1;
        letter-spacing: 0;
        padding: 15px 15px 0 15px;
        background-color: var(--white);
        display: flex;
    }

    .screenHeader2 {
        font-weight: var(--font-weight-semibold);
        font-style: var(--font-style-normal);
        font-size: 24px;
        color: var(--secondary-main);
        opacity: 1;
        letter-spacing: 0;
        background-color: var(--white);
        display: flex;
    }

    .content-card {
        border:1px solid #0007F3;
        border-radius: 8px;
    }
}

@media (min-width: 992px) {
    .pageContainer {
        padding: 0 30px 0 30px !important;
        background-color: var(--white);
    }

    .pageContainerMargin {
        margin: 0 24px 0 24px !important;
    }

    .screenHeader {
        font-weight: var(--font-weight-semibold);
        font-style: var(--font-style-normal);
        font-size: 24px;
        color: var(--secondary-main);
        opacity: 1;
        letter-spacing: 0;
        padding: 15px 30px 0 30px;
        background-color: var(--white);
        display: flex;
    }

    .screenHeader2 {
        font-weight: var(--font-weight-semibold);
        font-style: var(--font-style-normal);
        font-size: 24px;
        color: var(--secondary-main);
        opacity: 1;
        letter-spacing: 0;
        background-color: var(--white);
        display: flex;
    }

    .content-card {
        border:1px solid #0007F3;
        border-radius: 8px;
        /*min-height: 80px;*/
    }
}

.resAppBarContainer {
    border-bottom: 1px solid rgba(11, 64, 125, 0.3);
}

.resAppBarMenuOptions {
    line-height: 1;
    opacity: 1;
    margin-right: 25px;
    font-size: 16px;
    color: #333333 !important;
}

.resAppBarMenuOptions:hover {
    color: var(--primary) !important;
}

.resAppBarMenuOptionsDisable {
    line-height: 1;
    opacity: 1;
    margin-right: 29px;
    font-size: 16px;
    color: #999999 !important;
    cursor: not-allowed;
}

.resAppBarMenuOptionsDisable:hover {
    color: #999999 !important;
}

.activeMenuOption {
    color: var(--primary) !important;
    text-decoration: underline;
    opacity: 100%;
    font-weight: 500;
}

.activeMenuOption:hover {
    color: #0C407C !important;
}

.resAppBarAccountsDropdown {
    color: #0C407C !important;
    border: 1px solid #0C407C !important;
    /*background-color: #48709e;*/
    font-size: 12px;
}

.content-card-count {
    color: var(--black);
    font: normal normal var(--font-weight-semibold) 24px Barlow;
    letter-spacing: 0;
    justify-content: center;
}

.content-card-label {
    color: var(--black);
    font: normal normal var(--font-weight-semibold) 16px Barlow;
    letter-spacing: 0;
    justify-content: center
}

.bottom-border {
    border-bottom: 1px solid #D2D2D2;
}

.panel {
    background: var(--white) 0 0% no-repeat padding-box;
    box-shadow: 0 0 6px #00000029;
    border: 1px solid #D2D2D2;
    border-radius: 8px;
    opacity: 1;
}

.panel-header {
    text-align: left;
    font: normal var(--font-weight-bold) 18px Barlow;
    letter-spacing: 0;
    color: #707070;
    opacity: 1;
    height: 50px !important;
    display: flex;
    align-items: center;
}

.panel-subHeader {
    text-align: left;
    font: normal var(--font-weight-bold) 16px Barlow;
    letter-spacing: 0;
    color: #8B8B8B;
    opacity: 1;
    height: 40px !important;
    display: flex;
    align-items: center;
}

.panel-without-shadow {
    background: var(--white) 0 0% no-repeat padding-box;
    border: 1px solid #AAAAAA;
    border-radius: 8px;
    opacity: 1;
}

.label {
    text-align: left;
    font-weight: 400;
    /*font-weight: var(--font-weight-normal);*/
    letter-spacing: 0;
    font-size: 14px;
    color: var(--light-gray);
}

.content-text {
    font-weight: 500;
    letter-spacing: 0;
    font-size: 14px;
    color: #333333
}

.gray-lg-text {
    text-align: left;
    font-weight: var(--font-weight-bold);
    letter-spacing: 0;
    font-size: 18px;
    color: #B2B2B2
}

.hyperLink {
    color: var(--primary-xdark);
    font: normal var(--font-weight-semibold) 14px Barlow;
}

.hyperLink-disable {
    font: normal var(--font-weight-semibold) 14px Barlow;
}

.count-header {
    font-weight: var(--font-weight-bold);
    font-size: 16px;
    text-align: left;
    opacity: 1;
    color: #8B8B8B;
}

.outer-card {
    border: 1px solid #D2D2D2;
    min-height: 440px !important;
    border-radius: 8px !important;
}

.outer-card-select-segment {
    border: 1px solid #D2D2D2;
    min-height: 200px !important;
    border-radius: 8px !important;
}

.card-header {
    height: 42px;
    border-bottom: 2px solid #D2D2D2;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    color: var(--primary-xdark);
    background-color: var(--white);
}

.campaign-card-header {
    height: 42px;
    border-bottom: 2px solid #D2D2D2;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    color: var(--primary-xdark);
    background-color: var(--white);
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    padding-right: 16px;
    padding-left: 16px;
}

.inner-card {
    font-size: 14px;
    min-height: 312px;
    width: 100%;
    box-shadow: 0 0 6px #00000029 !important;
    border-radius: 8px;
}

.card-button-group {
    display: flex;
}

.card-button {
    width: 36px;
    height: 36px;
    border: 1px solid var(--light-gray);
    background: var(--white) 0 0% no-repeat padding-box;
    color: var(--black);
    padding-left: 10px;
    padding-top: 7px;
    margin-right: 10px
}
.card-button-disable {
    width: 36px;
    height: 36px;
    border: 1px solid var(--grey);
    background: var(--white) 0 0% no-repeat padding-box;
    color: var(--grey);
    padding-left: 10px;
    padding-top: 7px;
    margin-right: 10px
}
.other-icon-card-button {
    width: 36px;
    height: 36px;
    border: 1px solid #A1B9D4;
    background: var(--white) 0 0% no-repeat padding-box;
    color: var(--primary-xdark);
    padding-left: 10px;
    padding-top: 5px;
    margin-right: 10px
}
.legends-box {
    height: 20px;
    width: 20px;
    border-radius: 4px;
}

.input-field {
    /*background: #F5F5F5 0 0 no-repeat padding-box;*/
    border: 1px solid #B2B2B2;
    border-radius: 8px;
    opacity: 1;
}

.top-border {
    border-top: 1px solid #B2B2B2;
}

.right-border {
    border-right: 1px solid #dee2e6;
}

.left-border {
    border-left: 1px solid #dee2e6;
}


.css-ypiqx9-MuiDialogContent-root {
    overflow: initial !important;
}

.menuAccordion .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
    height: 35px !important;
    min-height: fit-content !important;
}

.menuAccordion .css-o4b71y-MuiAccordionSummary-content {
    margin: 0;
}

.schedule-time > .dl-date-picker > .MuiFormControl-root {
    min-width: inherit !important;
}

.bg-basic {
    background-color: #37B2BF;
}

.bg-pro {
    background-color: #127EF9;
}

.bg-business {
    background-color: #7169E5;
}

.bg-enterprise {
    background-color: #81B554;
}

.subscription-input > .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    background-color: red !important;
}

.date-format-batchui > .MuiChip-labelMedium {
    padding-left: 2px !important;
    padding-right: 2px !important;
}

/*Eye icon with border*/
.eyeIconBorder {
    border: 1px solid var(--light-gray);
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 6px 9px 6px 9px;
}
.eyeIconBorderDisabled {
    border: 1px solid var(--grey);
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 6px 9px 6px 9px;
}
.campaign-search-box > .dl-input-textfield > input {
    padding: 11.5px 14px !important;
}

.actionDiv > .MuiButton-outlined {
    min-width: 90px !important;
    min-height: 30px !important;
    padding: 0 16px !important;
    font-size: 18px !important;
}

.actionDiv > .MuiButton-contained {
    min-width: 90px !important;
    min-height: 33px !important;
    padding: 0 16px !important;
    box-shadow: none !important;
    font-size: 18px !important;
}

.dropdownMenu {
    padding: 6px 16px !important;
    font-size: 16px !important;
    font-family: var(--font-family-barlow) !important;
    color: var(--primary) !important;
}

.dropdownMenu:hover {
    background-color: white !important;
    text-decoration: underline !important;
}

.dropdownMenuDisable {
    padding: 10px 14px !important;
    font-size: 16px !important;
    color: rgba(0, 0, 0, 0.26) !important;;
    font-family: var(--font-family-barlow);
}

.dropdownMenuDisable:hover {
    background-color: #e5e5e5 !important;
    color: rgba(0, 0, 0, 0.26) !important;
}

.stats-rate-block {
    padding: 10px 20px;
    border-radius: 8px;
    border: 1px solid var(--black);
}

.user-activity-stats-block {
    /*padding: 10px 20px;*/
    /*border-radius: 8px;*/
    border-right: 1px solid var(--primary);
}
.stat-block-size{
    min-width: 100px;
    max-width: 160px;
}

.date-format-label {
    font-size: 16px;
    color: #666666;
    font-weight: 400;
}

.date-format-batchui {
    /*padding-top: 4px !important;*/
    /*padding-bottom: 6px !important;*/
    /*padding-right: 13px !important;*/
    /*padding-left: 13px !important;*/
    cursor: pointer !important;
    border-color: #9BA5AE !important;
    font-size: 14px !important;
    margin-top: 8px !important;
    font-weight: 500 !important;
    line-height: 17px !important;
    padding: 8px 16px 8px 16px !important;
}

.date-format-cross-icon {
    margin-left: 5px !important;
    margin-top: -2px;
    width: 10px;
    height: 10px;
    font-size: 9px;
}

.table-th {
    text-align: left;
    font-weight: var(--font-weight-semibold);
    letter-spacing: 0;
    font-size: 14px !important;
    color:var(--black);
    font-family: Barlow;
}

.table-th-sub-text{
    text-align: left;
    font-weight: var(--font-weight-normal);
    letter-spacing: 0;
    font-size: 12px !important;
    color:var(--black);
}

.table-td {
    text-align: left;
    /*font-weight: var(--font-weight-semibold) !important;*/
    letter-spacing: 0;
    font-size: 14px;
    color:var(--light-gray);
    font-family: Barlow;
}

.text-right {
    text-align: right !important;
}

.badge-size{
    font-size: 14px;
}
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;

}

.sticky-header{
    position: sticky;
    top: 0;
    background-color: white;
}

.header-title{
    font-weight: var(--font-weight-semibold);
    font-style: var(--font-style-normal);
    font-size: var(--font-size-lg);
    color: var(--secondary-main);
    margin-top: -2px;
}
.stats-block {
    text-align: center;
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    min-height: 75px;
    border-right: 1px solid var(--grey);
}
.stats-block-report {
    text-align: center;
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    min-height: 55px;
    border-right: 1px solid var(--grey);
}
.stats-day-block {
    text-align: center;
    min-height: 65px !important;
    border-right: 1.5px solid var(--grey);
}
@media (max-width: 992px) {
    .header-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 15px;
    }
    .table-th {
        text-align: left;
        font-weight: var(--font-weight-semibold);
        letter-spacing: 0;
        font-size: 12px !important;
        color: #666666;
    }

    .table-th-sub-text{
        text-align: left;
        font-weight: var(--font-weight-normal);
        letter-spacing: 0;
        font-size: 10px !important;
        color:var(--black);
    }

    .table-td {
        text-align: left;
        font-weight: var(--font-weight-semibold) !important;
        letter-spacing: 0;
        font-size: 12px;
        color: #333333;
    }
    .badge-size{
        font-size: 12px;
    }
    .stats-block {
        min-width: 130px;
    }
    .content-card-count {
        font: normal normal var(--font-weight-semibold) 16px Barlow;

    }
    .content-card-label{
     font-weight: 400;
    }

}

.circle-selected-icon {
    border-radius: 50px;
    margin-top: 8px;
    height: 13.5px;
    width: 13.5px;
    border: 3px solid var(--primary);
}

.text-ellipse{
    white-space: nowrap;
    min-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.dropdown .dropdown-menu li:hover{
    background-color: #f5f5f5 !important;
}
.btn-dropdown-campaign .dropdown-item:active{
    background-color: var(--grey) !important;
}


.content-badge {
    padding: 3px 15px 5px 15px;
    border-radius: 8px;
    font: normal var(--font-weight-semibold) 12px Barlow;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    max-height: 25px;
}

.content-badge-campaign {
    padding: 3px 15px 5px 15px;
    border-radius: 7px;
    font: normal var(--font-weight-semibold) 12px Barlow;
    width: max-content;
    max-height: 25px;
}
.error-box-border{
    border : 1px solid #D72F2F;
    padding: 11px;
    background-color: white;
}
.success-alert{
    background-color: #EFFEF1 !important;
    border: 1px solid var(--success);
    color : var(--success) !important;
}

.warning-alert{
    background-color: #FEF9EF !important;
    border: 1px solid var(--secondary-main);
    color : var(--secondary-main) !important;
}
.error-alert{
    background-color: #FEF3EF !important;
    border: 1px solid var(--danger);
    color : var(--danger) !important;
}
.info-alert{
    background-color: #EFF4FE !important;
    border: 1px solid var(--primary);
    color : var(--primary) !important;
}

.tab-container {
    display: flex;
}

.tab-section {
    display: flex;
}

.tab-section > .tabber {
    padding: 8px 16px;
    border: 2px dashed #999999;
    border-bottom-style: solid;
    border-right: none;
    border-radius: 4px 4px 0 0;
    margin-left: -1.8px;
    cursor: pointer;
    font-size: 16px;
    min-width: 100px;
    text-align: center;
}

.tab-section > .tabber:first-child {
    border-left: 2px dashed #999999;
}
.tab-section > .tabber:last-child {
    border-right: 2px dashed #999999;
}

.tab-section > .active-tabber {
    padding-bottom: 1px;
    border: 2px;
    border-style: solid;
    border-bottom-style: none;
    border-color: #999999;
    cursor: unset;
    color: var(--primary);
    font-weight: 500;
}

.tab-section > .active-tabber.tabber:first-child {
    border-left-style: solid;
}
.tab-section > .active-tabber.tabber:last-child {
    border-right-style: solid;
}


.tab-open {
    border-bottom: 2px solid #999999;
    flex-grow: 1;
}

.refresh-button{
    border: 1px solid var(--light-gray);
    padding: 7px;
    border-radius: 8px;
    min-height: 30px;
    min-width: 30px;
}
.right-strip-blue{
    width: 7px;
    background: #0007F3;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}
@media (max-width: 576px){
    .stats-block {
        min-width: 120px !important;
        max-width: 250px !important;
    }
}


@media (max-width: 767px){
    .btn-dropdown-campaign {
        padding-left: 6px !important;
        padding-right: 6px !important;
    }
    .stats-block {
        min-width: 130px !important;
        max-width: 250px !important;
    }
    .stats-day-block {
        min-width: 110px !important;
        max-width: 250px !important;
    }

}

.mode-batch{
    padding: 4px 12px 6px 12px;
    border-radius: 8px;
}

.mode-batch-campaign {
    padding: 4px 12px 6px 0px;
    border-radius: 8px;
}
/*@media (max-width: 1200px){*/
/*    .stats-block {*/
/*        min-width: 180px;*/
/*    }*/
/*}*/

/* SliderTooltip.css */

/* Customize the tooltip's background color and text color */
.sliderWrapper .MuiSlider-thumb.MuiSlider-thumbColorPrimary .MuiSlider-valueLabel {
    background-color: #FFFFFF; /* Change this to your desired tooltip background color */
    color: #333333; /* Change this to your desired tooltip text color */
    border: 1px solid #D2D2D2; /* Remove the default border */
    border-radius: 5px; /* Add some border radius */
    padding: 5px 10px; /* Adjust padding as needed */
    box-shadow: 0 4px 10px #00000040; /* Add a shadow for depth */
}
.mui-tab .MuiBox-root{
    min-height: 20px;
}
.mui-tab .MuiBox-root .MuiTabs-root {
    min-height: 20px;
}
.mui-tab .MuiBox-root .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root{
    padding: 0px 16px;
    min-height: 20px;
    text-transform: none;
}
.content-status-border{
    border: 1px solid #333333;
    border-radius: 15px;
}
.stepsVerticalLine{
    height: 15px;
    background: #75AEEF;
    width: 2.5px;
}

.ulLine{
    padding-inline-start: 0 !important;
    margin-bottom: 0 !important;
}

.ulLine::-webkit-scrollbar{
    display: none;
}

/*STARTS Whats app css*/
.tab-whatsapp-select {

}

.tab-whatsapp-select:first-child {
    border-left: 2px dashed #999999 !important;
}

.tab-whatsapp-select:last-child {
    border-right: 2px dashed #999999 !important;
}

.tab-whatsapp-active {
    border-top: 2px solid #666666;
    border-bottom: 3px solid white !important;
    margin-bottom: -2px;
    border-left: 2px solid #666666;
    border-right: 2px solid #666666;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    font-size: 16px;
    font-weight: 500;
    min-width: 80px;
    max-width: 120px;
    color: var(--primary)
}

.tab-whatsapp {
    border-top: 2px dashed #999999;
    border-bottom: 2px solid white !important;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    font-size: 16px;
    min-width: 80px;
    max-width: 120px;

}

.tab-border-bottom {
    border-bottom: 2px solid #666666;
}

.whatsapp-page-container {
    padding-right: 12px;
    padding-left: 12px;
}

.mobile-container {
    box-shadow: -2px -10px 20px #B3C1DD inset;
    border-radius: 20px;
    padding: 20px;
}

.mobile-header {
    background: #008069;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    height: 62px;
}

.mobile-top-content {
    color: var(--white);
    display: flex;
    justify-content: space-between;
    padding: 7px 13px 5px 13px;

}

.profile-icon {
    background-color: white;
    height: 24px;
    width: 24px;
    border-radius: 50px;
}

.mobile-footer {
    background: #F1EAE2;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    height: 4px;
}

.input-message-box {
    background-color: white;
    display: flex;
    align-items: center;
    margin-right: 4px;
    border-radius: 20px;
    padding-left: 11px;
    padding-right: 11px;
}

.box {
    width: 150px;
    background-color: white;
    color: #fff;
    position: relative;
    margin-top: 5px;
    margin-left: 12px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    float: left;
}

.listingBox {
    width: 220px;
    background-color: white;
    color: #fff;
    position: relative;
    /*margin-top: 5px;*/
    margin-left: 12px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    float: left;
}

.listingBox.arrow-left:after {
    content: " ";
    border-top-left-radius: 4px;
    height: 10px;
    position: absolute;
    left: -15px;
    top: 0;
    border-top: 0px solid transparent;
    border-right: 16px solid white;
    border-left: none;
    border-bottom: 15px solid transparent;
}

.box.arrow-left:after {
    content: " ";
    border-top-left-radius: 4px;
    height: 10px;
    position: absolute;
    left: -15px;
    top: 0;
    border-top: 0px solid transparent;
    border-right: 16px solid white;
    border-left: none;
    border-bottom: 15px solid transparent;
}

.message-padding {
    padding: 9px 9px 0 9px;
}

.pt-20 {
    padding-top: 20px;
}

.pb-20 {
    padding-bottom: 20px;
}

.mt-20 {
    margin-top: 20px;
}

.tooltip-content {
    background-color: #FFF9F1;
    border: 1px solid #FF9800;
    color: var(--black);
}

.custom-file-upload-whatsapp {
    border: 1px solid #ccc;
    display: inline-block;
    cursor: pointer;
    padding: 50px;
    text-align: center;
    width: 100%;
}
.whatspp-text-editor > .rdw-editor-wrapper > .rdw-editor-main {
    border-radius: 2px;
    margin-top: 10px;
    min-height: 200px;
    padding: 10px;
}
.campaign-text-editor > .rdw-editor-wrapper > .rdw-editor-main {
    border: 1px solid #c5c5c5;
}
.whatspp-text-editor > .rdw-editor-wrapper > .rdw-editor-toolbar  {
    border: none !important;
    padding: 0 !important;
}

/*ENDS Whats app css*/

.whatsapp-menu-option {
    color: var(--black);
}